import React, {createRef, useEffect, useRef} from "react";

import s from './ProjectsList.module.scss'
import f from '../../global/fonts.module.scss'
import Preloader from "../../store/preloader";
import DelayedLink from "../../components/DelayedLink/DelayedLink";
import {gsap} from "gsap";
import preloader from "../../store/preloader";
import {observer} from "mobx-react-lite";
import {autorun} from "mobx";
import Cursor from "../../store/cursor";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const ProjectList = observer(({projects, header}) => {

    const Render = () => {
        const headerRef = useRef()
        document.title = header.name

        const SingleProject = ({id, attributes}) => {
            const rev = createRef()
            const wrap = createRef()
            const title = createRef()
            const desc = createRef()

            useEffect(() => {
                autorun(()=>{
                    if(preloader.isShown === false){
                        gsap.timeline({
                            scrollTrigger: {
                                trigger: wrap.current,
                                start:'top center'
                            },
                            defaults: {
                                duration: 1,
                                ease:'expo'
                            }
                        }).to(rev.current, {
                            scaleX: 0,
                            left: '-120%',
                            transform: 'skew(-15deg)',
                            duration: 2
                        },0).from(title.current, {
                            y: '100%',
                            opacity: 0
                        },0.5).from(desc.current, {
                            y: '100%',
                            opacity: 0
                        },0.8)
                    }
                    ScrollTrigger.refresh()
                })

            }, [])

            const {name, Funded} = attributes

            return <DelayedLink
                onMouseEnter={()=>Cursor.setLinkHovered()}
                onMouseLeave={()=>Cursor.setLinkLeaved()}
                to={`/projects/${attributes.slug}`}
                onDelayStart={() => Preloader.isShown = true}
                delay={300}>
                <div ref={wrap} className={s.project} key={id}>
                    <div className={s.imgWrap}>
                        <img src={process.env.REACT_APP_BACKEND_URL + attributes.MainPhoto.data.attributes.url} alt=""/>
                        <div ref={rev} className={s.picReveal}/>
                    </div>
                    <div className={s.textWrap}>
                        <h5 ref={title} className={f.secularH5}>{name}</h5>
                        <p ref={desc} className={f.secularSubtitle1}>{Funded}</p>
                    </div>
                </div>
            </DelayedLink>
        }


        const projectsLayout = projects.data.map(({id, attributes}) =>
            <SingleProject key={id} id={id} attributes={attributes}/>
        )

        const {name} = header

        useEffect(()=>{
            autorun(()=>{
                if(preloader.isShown === false){
                    gsap.from(headerRef.current, {
                        opacity:0,
                        y: '100%'
                    })
                }
            })
        },[])

        window.scrollTo(0, 0)
        return <div className={s.wrap + ' side-margins'}>
            <div style={{overflow: "hidden"}}>
                <h1 ref={headerRef} className={f.didotH4}>{name}</h1>
            </div>
            <div className={s.grid}>
                {projectsLayout}
            </div>
        </div>
    }

    return <Render/>
})

export default ProjectList