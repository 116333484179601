import React, {useEffect, useRef, useState} from "react";

import s from './Block5.module.scss'
import f from '../../../global/fonts.module.scss'

import img from './img.png'
import {ReactComponent as Arrow} from "./arrow.svg";
import {useForm} from "react-hook-form";
import {gsap} from "gsap";
import Cursor from "../../../store/cursor";
import {SEND_CALLBACK} from "../../../queries/sendCallback";
import {useMutation} from "@apollo/client";


const Block5 = () => {

    const formRef = useRef()
    const picRef = useRef()
    const titleRef = useRef()
    const wrapRef = useRef()

    const [showResponse, setShowResponse] = useState(false)

    useEffect(() => {
        gsap.timeline({
            defaults: {
                duration: 1,
                ease: 'expo'
            },
            scrollTrigger: {
                trigger: wrapRef.current,
                start: 'top center'
            }
        })
            .from(titleRef.current, {
                opacity: 0,
                y: '100%'
            }, 0)
            .to(picRef.current, {
                scaleX: 0,
                left: '-120%',
                transform: 'skew(-15deg)',
                duration: 2
            }, 0.1)
            .from(formRef.current, {
                opacity: 0
            }, 0.5)
    }, [])

    const Form = () => {
        const {register, formState: {errors}, handleSubmit} = useForm()
        const [createCallback, {data, loading, error}] = useMutation(SEND_CALLBACK)

        const onSubmit = async (d) => {
            const res = await createCallback({
                variables: {
                    name: d.name,
                    phone: d.phone,
                    email: d.email,
                    message: d.message
                }
            })
            setShowResponse(true)
        }

        return <div>
            {
                showResponse
                    ? <p className={f.interH5Light}>Your message has been successfully sent</p>
                    : <form onSubmit={handleSubmit(onSubmit)}>
                        <h4 ref={titleRef} className={`${f.didotH4} ${s.showOnPhone}`}>We will call you</h4>
                        <div className={s.fields}>
                            <div className={s.inputs}>
                                <input className={f.secularH5} required placeholder={'NAME'}
                                       type="text" {...register('name')}/>
                                <input className={f.secularH5} placeholder={'PHONE'}
                                       type="text" {...register('phone')}/>
                                <input className={f.secularH5} placeholder={'EMAIL'}
                                       type="text" {...register('email')}/>
                            </div>
                            <textarea className={f.secularH5} placeholder={'MESSAGE'} {...register('message')}/>
                        </div>

                        <button
                            type={"submit"}
                            onMouseEnter={() => Cursor.setLinkHovered()}
                            onMouseLeave={() => Cursor.setLinkLeaved()}
                        >
                            <p className={f.secularH5}>SEND</p><Arrow/>
                        </button>
                    </form>
            }
        </div>
    }

    const Render = () => {
        return <div className={s.wrap + ' side-margins'} ref={wrapRef} style={{display: 'none'}}>
            <div className={`${s.animtext} ${s.hideOnPhone}`}>
                <h4 ref={titleRef} className={`${f.didotH4} `}>We will contact you</h4>
            </div>
            <div className={s.block}>
                <Form/>
                <div className={s.imageWrap}>
                    <div className={s.pseudoWrap}>
                        <img className={s.image} src={img} alt="photo"/>
                        <div className={s.imgReveal} ref={picRef}/>
                    </div>
                </div>
            </div>
        </div>
    }

    return <Render/>
}

export default Block5