import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from "react-router-dom";
import MainPage from './pages/main/MainPage'

import s from './global/global.scss'
import './global/reset.scss'
import './global/variables.scss'
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {ApolloProvider} from "@apollo/client";
import client from "./utils/apolloClient";
import Category from "./pages/category/Category";
import Project from "./pages/project/Project";
import ContactUs from "./components/ContactUs/ContactUs";
import AllProjects from "./pages/allProjects/allProjects";
import Preloader from "./components/Preloader/Preloader";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Cursor from "./components/Cursor/Cursor";


const routes = [
    { path: '/', Component: <MainPage/> },
    { path: '/category/:id', Component: <Category/> },
    { path: '/projects/:id', Component: <Project/> },
    { path: '/projects', Component: <AllProjects/> },
]

gsap.registerPlugin(ScrollTrigger)

const App = () => {



    const Render = () => {
        const { pathname, hash, key } = useLocation();

        useEffect(() => {
            // if not a hash link, scroll to top
            if (hash === '') {
                window.scrollTo(0, 0);
            }
            // else scroll to id
            else {
                setTimeout(() => {
                    const id = hash.replace('#', '');
                    const element = document.getElementById(id);
                    if (element) {
                        console.log(element.offsetTop)
                        window.scrollTo(0, element.offsetTop)

                    }
                }, 0);
            }
        }, [pathname, hash, key]); // do this on route change

        return <>
            <Cursor/>
            <Preloader disabled/>
            <Header/>
            <div className={'content'}>
                <Routes>
                    {
                        routes.map(({path, Component})=>{
                            return <Route
                                key={path}
                                path={path}
                                element={Component}
                            />
                        })
                    }
                    <Route
                        path='*'
                        element={<Navigate to="/"/>}
                    />
                </Routes>
            </div>
            <ContactUs/>
            <Footer/></>
    }

    return <ApolloProvider client={client}>
        <Router>
            <Render/>
        </Router>
    </ApolloProvider>
}

ReactDOM.render(
        <App/>,
    document.getElementById('root')
);
