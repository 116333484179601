import React, {useEffect, useRef} from "react";

import s from './RandomProjects.module.scss'
import f from '../../../global/fonts.module.scss'
import {useQuery} from "@apollo/client";
import {ALL_PROJECTS} from "../../../queries/project";
import DelayedLink from "../../../components/DelayedLink/DelayedLink";
import Preloader from "../../../store/preloader";
import {gsap} from "gsap";
import Cursor from "../../../store/cursor";

const RandomProjects = () => {
    const {data, loading, error} = useQuery(ALL_PROJECTS);
    if (loading) return ''
    if (error) console.log(error.message)

    const projects = data.projects.data.map((item) => item)
    const shuffled = [...projects].sort(() => 0.5 - Math.random())
    const randomProjects = shuffled.slice(0, 2)


    const SingleProject = ({id, attributes}) => {
        const wrapRef = useRef()
        const revRef = useRef()
        const titleRef = useRef()
        const descRef = useRef()


        useEffect(() => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: wrapRef.current,
                    start: 'top center'
                },
                defaults: {
                    duration: 1,
                    ease: 'expo'
                }
            }).to(revRef.current, {
                scaleX: 0,
                left: '-120%',
                transform: 'skew(-15deg)',
                duration: 2
            }, 0).from(titleRef.current, {
                y: '100%',
                opacity: 0
            }, 0.5).from(descRef.current, {
                y: '100%',
                opacity: 0
            }, 0.8)
        }, [])

        return <DelayedLink key={id} to={`/projects/${attributes.slug}`}
                            delay={300}
                            onDelayStart={() => Preloader.isShown = true}
                            onMouseEnter={()=>Cursor.setLinkHovered()}
                            onMouseLeave={()=>Cursor.setLinkLeaved()}
        >
            <div className={s.projectWrap} ref={wrapRef}>
                <div style={{overflow: "hidden", position: "relative"}}>
                    <img src={process.env.REACT_APP_BACKEND_URL + attributes.MainPhoto.data.attributes.url}
                         alt={attributes.name}/>
                    <div className={s.picReveal} ref={revRef}/>
                </div>
                <h5 ref={titleRef} className={f.secularH5}>{attributes.name}</h5>
                <h6 ref={descRef} className={f.secularSubtitle1}>{attributes.Funded}</h6>
            </div>
        </DelayedLink>
    }

    const views = randomProjects.map((item) => <SingleProject key={item.id} {...item}/>)

    const Render = () => {
        const titleRef = useRef()
        const wrapRef = useRef()

        useEffect(() => {
            gsap.from(titleRef.current, {
                scrollTrigger: {
                    trigger: wrapRef.current
                },
                y: '100%',
                opacity: 0
            })
        })


        return <div className={s.wrap} ref={wrapRef}>
            <div style={{overflow: "hidden"}}>
                <h4 ref={titleRef} className={f.didotH4}>Our other projects</h4>
            </div>
            <div className={s.grid}>
                {views}
            </div>
        </div>
    }

    return <Render/>
}

export default RandomProjects