import gql from "graphql-tag";

export const SEND_CALLBACK = gql`
    mutation senCallback(
        $name: String,
        $phone: String,
        $email: String,
        $message: String
    ){
        createCallback(data:{name: $name, phone:$phone, email:$email, message: $message}){
            data{
                attributes{
                    name
                    phone
                    email
                    message
                }
            }
        }
    }
`