import React from 'react'
import Block1 from "./Block1/Block1";
import Block2 from "./Block2/Block2";
import Block3 from "./Block3/Block3";
import Block4 from "./Block4/Block4";
import Block5 from "./Block5/Block5";
import {useQuery} from "@apollo/client";
import {FRONTPAGE_QUERY_BLOCK1} from "../../queries/mainpage";
import Preloader from "../../store/preloader";



const MainPage = () =>{
    const {data, loading, error} = useQuery(FRONTPAGE_QUERY_BLOCK1);

    Preloader.show()
    if(loading) return ''
    if(error) console.log(error.message)

    window.scrollTo(0,0)

    document.title = 'Pantheon'


    const render = () => {
        console.log('rendered')
        return <div>
            <Block1 data={data.block1}/>
            <Block2 data={data.block2}/>
            <Block3 response={data.categories}/>
            <Block4 data={data.block4}/>
            <Block5/>
        </div>
    }

    return render()

}

export default MainPage
