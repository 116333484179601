import React, {createRef, useEffect, useRef} from "react";
import {gsap} from "gsap";

import s from './Block4.module.scss'
import f from '../../../global/fonts.module.scss'

const Block4 = ({data}) => {
    let refArray = useRef([])

    const wrapRef = useRef()
    const short = data.data.attributes
    const blocks = [
        {header: short.block1header, text: short.block1text},
        {header: short.block2header, text: short.block2text},
        {header: short.block3header, text: short.block3text},
        {header: short.block4header, text: short.block4text},
        {header: short.block5header, text: short.block5text},
        {header: short.block6header, text: short.block6text},
    ]

    const blocksLayout = blocks.map(({header, text}, i) => {
        const countRef = createRef()
        const titleRef = createRef()
        const textRef = createRef()

        refArray.current.push({
            count: countRef,
            title: titleRef,
            text: textRef,
        })

        return header ?
            <div key={i} className={s.block}>
                <h2 ref={countRef}
                    className={f.interH1Bold}
                    style={{opacity: 1 - i * 0.1}}
                >{i + 1}</h2>
                <div className={s.text}>
                    <div className={s.animtext}>
                        <h5 ref={titleRef}>{header}</h5>
                    </div>
                    <p ref={textRef} className={f.interH5Light}>{text}</p>
                </div>
            </div>
            : null
    })


    useEffect(() => {
        refArray.current.forEach(({count, title, text}, i) => {
            gsap.timeline({
                defaults: {
                    duration: 1,
                    ease: 'expo',
                    delay: 0
                },
                scrollTrigger: {
                    trigger: wrapRef.current,
                }
            }).from(count.current, {
                opacity: 0,
                x: 35
            }, 0.1 * i).from(title.current, {
                opacity: 0,
                y: '100%',
            }, 0.3 * i).from(text.current, {
                opacity: 0
            }, 0.6 * i)

        })
    }, [blocksLayout])


    return <div className={s.wrap  + ' side-margins'} ref={wrapRef} id="strategy">
        <h4 className={f.didotH4}>{short.title}</h4>
        <div className={s.grid}>
            {blocksLayout}
        </div>
    </div>
}

export default Block4