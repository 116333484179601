import React, {useEffect, useRef, useLayoutEffect} from "react";
import s from './Project.module.scss'
import f from '../../global/fonts.module.scss'
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {PROJECT_BY_SLUG} from "../../queries/project";
import ReactMarkdown from "react-markdown";
import {MapContainer, Marker, TileLayer} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import RandomProjects from "./RandomProjects/RandomProjects";
import iconURL from './map.png'
import Preloader from "../../store/preloader";
import {gsap} from "gsap";
import {autorun} from "mobx";
import preloader from "../../store/preloader";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Project = () => {
    const {id} = useParams()

    const {data, loading, error} = useQuery(PROJECT_BY_SLUG(id));

    Preloader.show()
    window.scrollTo(0, 0)
    if (loading) return ''
    if (error) console.log(error.message)


    const short = data.projects.data[0]
    const {name, Funded, Details, Size, Sector, Status, Partner, detailsHeader, Coordinates, Address} = short.attributes
    const mainPhotoUrl = short.attributes.MainPhoto.data.attributes.url

    document.title = name

    // Получаем массив ссылок на фото
    let photosURLS = []
    if (short.attributes.Photos) {
        photosURLS = short.attributes.Photos.data.map(({attributes}) => {
            return attributes.url
        })
    }
    //Отдельный URL для большой фотки после сетки
    let bigPicURL = ''
    if (photosURLS.length % 2 === 1) {
        bigPicURL = photosURLS.pop()
    }
    const SinglePicture = ({url, i}) => {
        const wrapRef = useRef()
        const revRef = useRef()

        useLayoutEffect(() => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: wrapRef.current,
                    start: "top center"
                },
                defaults: {
                    duration: 1,
                    ease: "expo"
                }
            }).to(revRef.current, {
                scaleX: 0,
                left: '-120%',
                transform: 'skew(-15deg)',
                duration: 2
            }, .2 * (i % 2))
        }, [])

        return <div ref={wrapRef} style={{position: "relative", overflow: "hidden"}}>
            <img key={i} className={s.gridPic} src={process.env.REACT_APP_BACKEND_URL + url} alt={name}/>
            <div ref={revRef} className={s.picReveal}/>
        </div>
    }

    const photosGrid = photosURLS.map((url, i) => {
        return <SinglePicture key={i} url={url} i={i}/>
    })

    let position = [51.505, -0.09]
    if (Coordinates) {
        position = Coordinates.split(',')
    }

    const icon = new L.icon({
        iconUrl: iconURL,
        iconSize: [40, 40],
    })


    const Render = () => {
        const titleRef = useRef()
        const mainPicRef = useRef()

        //projectDetails
        const pdWrapRef = useRef()
        const pdTitleRef = useRef()
        const pdHeaderRef = useRef()
        const pdTextRef = useRef()

        //Description
        const descWrapRef = useRef()
        const descTitleRef = useRef()
        const fundedRef = useRef()
        const sizeRef = useRef()
        const sectorRef = useRef()
        const statusRef = useRef()
        const partnerRef = useRef()

        //bigPicture
        const bigpicRef = useRef()
        const bigpicWrapRef = useRef()

        //Map
        const mapWrapRef = useRef()
        const mapRef = useRef()
        const addressTitleRef = useRef()
        const addressRef = useRef()

        useLayoutEffect(() => {
            autorun(() => {
                if (preloader.isShown === false) {
                    gsap.from(titleRef.current, {
                        opacity: 0,
                        y: '100%',
                        duration: 1,
                        ease: 'expo'
                    })
                    gsap.from(mainPicRef.current, {
                        opacity: 0,
                        y: '100%',
                        duration: 1,
                        ease: 'expo'
                    })
                }
            })
            //projectDetails
            gsap.timeline({
                scrollTrigger: {
                    trigger: pdWrapRef.current,
                    start: 'top center',
                },
                defaults: {
                    ease: 'expo',
                    duration: 1
                }
            }).from(pdTitleRef.current, {
                x: 50,
                opacity: 0
            }, 0).from(pdHeaderRef.current, {
                opacity: 0,
                x: 150
            }, .3).from(pdTextRef.current, {
                opacity: 0
            }, .7)

            //Description
            gsap.timeline({
                scrollTrigger: {
                    trigger: descWrapRef.current,
                    start: 'top center',
                },
                defaults: {
                    ease: 'expo',
                    duration: 1
                }
            }).from(descTitleRef.current, {
                x: 50,
                opacity: 0
            }, 0).from(fundedRef.current, {
                opacity: 0,
                x: 150
            }, .3).from(sizeRef.current, {
                opacity: 0,
                x: 150
            }, .4).from(sectorRef.current, {
                opacity: 0,
                x: 150
            }, .5).from(statusRef.current, {
                opacity: 0,
                x: 150
            }, .6).from(partnerRef.current, {
                opacity: 0,
                x: 150
            }, .7)

            //BigPicture
            console.log('bpic set')
            gsap.from(bigpicRef.current, {
                opacity: 0,
                y: '100px',
                scrollTrigger: {
                    trigger: bigpicWrapRef.current,
                    start: 'top+=100px bottom'
                }
            })


            //Map
            console.log('map set')
            gsap.timeline({
                scrollTrigger: {
                    trigger: mapWrapRef.current,
                },
                defaults:{
                    delay: 1,
                    ease:'expo',
                    duration:1
                }
            }).from(mapRef.current,{
                opacity:0,
                y:100
            }, 0).from(addressTitleRef.current,{
                opacity: 0,
                x: 50
            }, 0.1).from(addressRef.current,{
                opacity: 0,
                x: 50
            }, .5)

            ScrollTrigger.refresh()

            return () => {
                gsap.timeline().kill()
            }
        }, [])

        return <div className={s.wrap}>
            <div className={s.headerWrapper  + ' side-margins'}>
                <h1 ref={titleRef}>{name}</h1>
            </div>
            <img ref={mainPicRef} className={s.mainPhoto} src={process.env.REACT_APP_BACKEND_URL + mainPhotoUrl}
                 alt={name}/>
            <div className={s.infoWrap + ' side-margins'}>
                <div className={s.textWrap}>
                    <div className={s.textContainer} ref={pdWrapRef}>
                        <h6 ref={pdTitleRef} className={f.secularH6}>PROJECT DETAILS</h6>
                        <h5 ref={pdHeaderRef}>{detailsHeader}</h5>
                        <div ref={pdTextRef} className={f.interBody1}>
                            <ReactMarkdown>
                                {Details}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>
                <div ref={descWrapRef} className={s.specWrap}>
                    <div className={s.specContainer}>
                        <h6 ref={descTitleRef} className={f.secularH6}>DESCRIPTION</h6>
                        <div className={s.col}>
                            {
                                Funded && <div className={s.specBlock} ref={fundedRef}>
                                    <h6>Funded</h6>
                                    <p className={f.interBody1}>{Funded}</p>
                                </div>
                            }
                            {
                                Size && <div className={s.specBlock} ref={sizeRef}>
                                    <h6>Size</h6>
                                    <p className={f.interBody1}>{Size}</p>
                                </div>
                            }
                            {
                                Sector && <div className={s.specBlock} ref={sectorRef}>
                                    <h6>Sector</h6>
                                    <p className={f.interBody1}>{Sector}</p>
                                </div>
                            }
                            {
                                Status && <div className={s.specBlock} ref={statusRef}>
                                    <h6>Status</h6>
                                    <p className={f.interBody1}>{Status}</p>
                                </div>
                            }
                            {
                                Partner && <div className={s.specBlock} ref={partnerRef}>
                                    <h6>Partner</h6>
                                    <p className={f.interBody1}>{Partner}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                photosURLS.length !== 0 && <div className={s.photos}>
                    <div className={s.photoGrid + ' side-margins'}>
                        {photosGrid}
                    </div>
                    {
                        photosURLS.length % 2 !== 1 &&
                        <div className={s.bigPicture} ref={bigpicWrapRef}>
                            <img ref={bigpicRef}
                                 src={process.env.REACT_APP_BACKEND_URL + bigPicURL} alt={name}/>
                        </div>
                    }
                </div>
            }
            {
                Coordinates && Address &&
                <div className={s.mapWrap} ref={mapWrapRef} >
                    <div className={s.map + ' text-block'} ref={mapRef}>
                        <MapContainer center={position} zoom={14} scrollWheelZoom={false}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={position} icon={icon}/>
                        </MapContainer>
                    </div>
                    <div className={s.addressWrap}>
                        <div className={s.address}>
                            <h4 ref={addressRef}>{Address}</h4>
                            <p ref={addressTitleRef} className={f.secularH6}>ADDRESS</p>
                        </div>
                    </div>
                </div>
            }
            <div className={s.otherProjectsWrap + ' side-margins'}>
                <RandomProjects/>
            </div>
        </div>
    }

    return <Render/>

}

export default Project