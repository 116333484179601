import s from './Header.module.scss'
import f from '../../global/fonts.module.scss'

import {ReactComponent as Icon} from "./logo.svg";
import DelayedLink from "../DelayedLink/DelayedLink";
import Preloader from "../../store/preloader";
import Cursor from "../../store/cursor";
import MobileMenu from "./MobileMenu/MobileMenu";
import {Link} from "react-router-dom";


const Header = () => {
    return <div className={s.wrap}>
        <DelayedLink
            to='/'
            onDelayStart={() => Preloader.isShown = true}
            delay={300}>
            <div onMouseEnter={() => Cursor.setLinkHovered()}
                 onMouseLeave={() => Cursor.setLinkLeaved()}
                 className={s.logo}><Icon/></div>
        </DelayedLink>

        <div className={s.line}/>
        <div className={s.navs}>
            <Link onMouseEnter={() => Cursor.setLinkHovered()}
                  onMouseLeave={() => Cursor.setLinkLeaved()}
                  className={`${s.navLink} ${f.secularSubtitle1}`}
                  to="/#about">About us</Link>
            {/* <Link onMouseEnter={() => Cursor.setLinkHovered()}
                  onMouseLeave={() => Cursor.setLinkLeaved()}
                  className={`${s.navLink} ${f.secularSubtitle1}`}
                  to="/#works">Transactions</Link> */}
            <Link onMouseEnter={() => Cursor.setLinkHovered()}
                  onMouseLeave={() => Cursor.setLinkLeaved()}
                  className={`${s.navLink} ${f.secularSubtitle1}`}
                  to="/#strategy">Strategy</Link>
            <Link onMouseEnter={() => Cursor.setLinkHovered()}
                      onMouseLeave={() => Cursor.setLinkLeaved()}
                      className={`${s.navLink} ${f.secularSubtitle1}`}
                      to="/#contacts">Contact</Link>
        </div>
        <MobileMenu/>
    </div>
}

export default Header