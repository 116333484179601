import React from "react";

import {useQuery} from "@apollo/client";
import {ALL_PROJECTS} from "../../queries/project";
import Preloader from "../../store/preloader";
import ProjectList from "../../components/ProjectsList/ProjectsList";

const AllProjects =() =>{
    const {data, loading, error} = useQuery(ALL_PROJECTS);

    Preloader.show()
    if (loading) return ''
    if (error) console.log(error.message)


    return <ProjectList header={{name:'Our works'}} projects={data.projects}/>
}

export default AllProjects