import React, {useEffect, useRef, useState, useLayoutEffect} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


import s from './Block1.module.scss'
import Preloader from "../../../store/preloader";
import {observer} from "mobx-react-lite";
import Marquee from "react-fast-marquee";

const Block1 = observer(({data}) => {


    const baseURL = process.env.REACT_APP_BACKEND_URL

    const img1Ref = useRef()
    const img2Ref = useRef()
    const img3Ref = useRef()

    const [x, setX] = useState()
    const [y, setY] = useState()

    useEffect(
        () => {
            const update = (e) => {
                setX(e.x)
                setY(e.y)
            }
            window.addEventListener('mousemove', update)
            window.addEventListener('touchmove', update)

            return () => {
                window.removeEventListener('mousemove', update)
                window.removeEventListener('touchmove', update)
            }
        },
        [setX, setY]
    )

    const handleMove = (e) => {
        gsap.to(e.current, {
            left: x * 1.5,
            top: y * 1.3,
        },)
    }
    const img1WrapRef = useRef()
    const logoRef = useRef()

    useEffect(() => {
        if (Preloader.isShown === false) {
            gsap.from(logoRef.current, {
                duration: 1,
                ease:'expo',
                y: '100%',
                opacity: '0',
            })
        }
    }, [Preloader.isShown])

    ScrollTrigger.refresh()
    return <div className={s.wrap + ' side-margins'}>
        <div className={s.desc + ' text-block'}>
            <p style={{textTransform:'uppercase'}}>
                Pantheon is a
                <strong onMouseMove={() => handleMove(img1Ref)}> Private Equity <img ref={img1Ref}
                                                                              src={baseURL + data.data.attributes.hoverImage1.data.attributes.url}
                                                                              alt=""/></strong>
                real estate company
                <strong onMouseMove={() => handleMove(img2Ref)}> operating  <img ref={img2Ref}
                                                                                      src={baseURL + data.data.attributes.hoverImage2.data.attributes.url}
                                                                                      alt=""/></strong>
                in the
                <strong onMouseMove={() => handleMove(img3Ref)}> UK <img ref={img3Ref}
                                                                               src={baseURL + data.data.attributes.hoverImage3.data.attributes.url}
                                                                               alt=""/></strong>
                and Western Europe
            </p>
        </div>

        <div className={s.logoWrap} ref={img1WrapRef}>
            <img className={s.imgLogo} ref={logoRef}
                 src={baseURL + data.data.attributes.TextLogo.data.attributes.url}
                 alt=""

            />
        </div>
        <div className={s.logoWrapM}>
            <Marquee
                gradient={false}
                speed={100}
            >
                <div className={s.logoLine}>
                    <img className={s.imgLogo}
                         src={baseURL + data.data.attributes.TextLogo.data.attributes.url}
                         alt=""
                    />
                </div>
                <div className={s.logoLine}>
                    <img className={s.imgLogo}
                         src={baseURL + data.data.attributes.TextLogo.data.attributes.url}
                         alt=""
                    />
                </div>
            </Marquee>
        </div>
    </div>
})

export default Block1