import React from "react";


import {useQuery} from "@apollo/client";
import {ALL_PROJECTS_FROM_CATEGORY} from "../../queries/category";
import {useParams} from "react-router-dom";
import Preloader from "../../store/preloader";
import {observer} from "mobx-react-lite";
import ProjectList from "../../components/ProjectsList/ProjectsList";

const Category = observer(() => {
    const {id} = useParams()

    const {data, loading, error} = useQuery(ALL_PROJECTS_FROM_CATEGORY(id));

    Preloader.show()
    if (loading) return ''
    if (error) console.log(error.message)


    return <ProjectList header={data.categories.data[0].attributes} projects={data.projects}/>
})

export default Category