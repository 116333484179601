import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import PreloaderState from "../../store/preloader";
import { gsap } from "gsap";

import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";

import s from "./Preloader.module.scss";
import preloader from "../../store/preloader";

const Preloader = observer(({ disabled }) => {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const tl = gsap.timeline({
    defaults: {
      delay: 0,
      duration: 1,
      ease: "expo",
      stagger: 10,
    },
  });

  useEffect(() => {
    if (preloader.isShown) {
      tl.from(ref1.current, { x: "100%" })
        .from(ref2.current, { y: "100%" }, 0.2)
        .from(ref3.current, { y: "-100%" }, 0.5);
    }
  }, [preloader.isShown]);

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, []);

  return (
    <div
      className={`${s.wrap} ${PreloaderState.isShown > 0 && s.visible}`}
      style={{
        display: disabled && "none",
      }}
    >
      <div className={s.layout}>
        <div className={s.img1}>
          <img ref={ref1} src={img1} alt="" />
        </div>
        <div className={s.img2}>
          <img ref={ref2} src={img2} alt="" />
        </div>
        <div className={s.img3}>
          <img ref={ref3} src={img3} alt="" />
        </div>
      </div>
    </div>
  );
});

export default Preloader;
