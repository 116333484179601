import s from './Block2.module.scss'
import f from '../../../global/fonts.module.scss'
import {useEffect, useRef} from "react";
import {gsap} from "gsap";

const Block2 = ({data}) => {


    const bRef1 = useRef()
    const bRefWrap1 = useRef()
    const yearRefWrap = useRef()
    const yearRefCounter = useRef()
    const yearRefTitle = useRef()
    const yearRefDesc = useRef()

    const devRefWrap = useRef()
    const devRefCounter = useRef()
    const devRefTitle = useRef()
    const devRefDesc = useRef()

    const acqRefWrap = useRef()
    const acqRefCounter = useRef()
    const acqRefTitle = useRef()
    const acqRefDesc = useRef()

    const pipeRefWrap = useRef()
    const pipeRefCounter = useRef()
    const pipeRefTitle = useRef()
    const pipeRefDesc = useRef()

    const blockAnim = ({wrap, counter, title, desc}) => {
        gsap.timeline({
            scrollTrigger: {
                trigger: wrap,
            }
        })
            .from(counter, {x:'50px', opacity:0})
            .from(title, {y:'100%', opacity:0})
            .from(desc, {opacity:0})
    }

    useEffect(() => {
        //Overview animation
        gsap.fromTo(bRef1.current, {
            y: '100px',
            opacity: '0',
        }, {
            y: '0',
            opacity: '1',
            duration: 1,
            scrollTrigger: {
                trigger: bRefWrap1.current,
                start: 'top+=100px bottom-=20px',
            }
        })

        //Stats animation
        blockAnim({
            wrap: yearRefWrap.current,
            counter: yearRefCounter.current,
            title: yearRefTitle.current,
            desc: yearRefDesc.current
        })
        blockAnim({
            wrap: devRefWrap.current,
            counter: devRefCounter.current,
            title: devRefTitle.current,
            desc: devRefDesc.current
        })
        blockAnim({
            wrap: acqRefWrap.current,
            counter: acqRefCounter.current,
            title: acqRefTitle.current,
            desc: acqRefDesc.current
        })
        blockAnim({
            wrap: pipeRefWrap.current,
            counter: pipeRefCounter.current,
            title: pipeRefTitle.current,
            desc: pipeRefDesc.current
        })

    }, [])


    const texts = data.data.attributes
    return <div className={s.wrap + ' side-margins'} id="about">
        <div className={s.overviewWrap} ref={bRefWrap1}>
            <div ref={bRef1}>
                <h4 className={`${f.didotH4} ${s.title}`}>{texts.TextHeader}</h4>
                <p className={`${f.interH4Light} ${s.text} text-block`}>{texts.Overview}</p>
            </div>
        </div>
        <div className={s.statsWrap} style={{ display: 'none' }}>
            <div className={s.col1} ref={yearRefWrap}>
                <p className={s.counter} ref={yearRefCounter}>{texts.Years}</p>
                <div className={s.animOverflow}>
                    <p className={f.didotH4} ref={yearRefTitle}>+ Years</p>
                </div>
                <p className={`${f.interH5Light} ${s.info}`} ref={yearRefDesc}>of combined private equity real estate investment <br/>experience
                </p>
            </div>
            <div className={s.col2}>
                <div className={s.stat} ref={devRefWrap}>
                    <h4 ref={devRefCounter} className={`${f.didotH4} ${s.counter}`}>{texts.Assets}</h4>
                    <h5 ref={devRefTitle} className={s.title}>+ Assets</h5>
                    <h6 ref={devRefDesc} className={f.interH6Bold}>advised on and valued at £1.6 billion</h6>
                </div>
                <div className={s.stat} ref={acqRefWrap}>
                    <h4 ref={acqRefCounter} className={`${f.didotH4} ${s.counter}`}>{texts.Countries}</h4>
                    <h5 ref={acqRefTitle} className={s.title}>+ Countries </h5>
                    <h6 ref={acqRefDesc} className={f.interH6Bold}>of operation</h6>
                </div>
                <div className={s.stat} ref={pipeRefWrap}>
                    <h4 ref={pipeRefCounter} className={`${f.didotH4} ${s.counter}`}>£ {texts.Pipeline} </h4>
                    <h5 ref={pipeRefTitle} className={s.title}>+ Million</h5>
                    <h6 ref={pipeRefDesc} className={f.interH6Bold}>of capital deployed</h6>
                </div>
            </div>
        </div>
    </div>
}

export default Block2