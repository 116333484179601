import gql from "graphql-tag";

export const FRONTPAGE_QUERY_BLOCK1 = gql`
    query block1{
        block1{
            data{
                id
                attributes{
                    Description
                    TextLogo{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                    }
                    hoverImage1{
                        data{
                            attributes{
                                url
                            }
                        }
                    }
                    hoverImage2{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                    }
                    hoverImage3{
                        data{
                            id
                            attributes{
                                url
                            }
                        }
                    }
                }
            }
        }
        block2{
            data{
                attributes{
                    TextHeader
                    Overview
                    Years
                    Assets
                    Countries
                    Pipeline
                }
            }
        }
        categories {
            data {
                id
                attributes {
                    name
                    slug
                    description
                    cover {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
        block4{
            data{
                attributes{
                    title
                    block1text
                    block2text
                    block3text
                    block4text
                    block5text
                    block6text
                    block1header
                    block2header
                    block3header
                    block4header
                    block5header
                    block6header
                }
            }
        }
    }
`;

export const PROJECTS_COUNT_BY_ID = (id) => gql`
    query count{
        projects(filters:{category:{id:{eq:${id}}}}){
            meta{
                pagination{
                    total
                }
            }
        }
    }
`


export const CONTACT_US = gql`
    query contacts{
        contactUs{
            data{
                attributes{
                    phone
                    email
                    address
                    linkedinLink
                    twitterLink
                    adressCoordinates
                }
            }
        }
    }
`
