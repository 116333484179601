import React, {useEffect, useRef} from "react";

import s from './ContactUs.module.scss'
import f from '../../global/fonts.module.scss'
import {CONTACT_US} from "../../queries/mainpage";
import {useQuery} from "@apollo/client";
import {gsap} from "gsap";

const ContactUs = () => {
    const {data, loading, error} = useQuery(CONTACT_US);
    if (loading) return ''
    if (error) console.log(error.message)

    const values = data.contactUs.data.attributes

    const Render = () => {
        const titleRef = useRef()
        const sectionRef = useRef()
        const wrapRef = useRef()

        useEffect(() => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: wrapRef.current,
                    start: 'bottom bottom',
                    toggleActions: 'play none none reverse'
                },
                defaults: {
                    ease: 'expo',
                    duration: 1
                }
            }).from(titleRef.current, {
                opacity: 0,
                y: '100%'
            }).from(sectionRef.current, {
                opacity: 0,
                y: 35,
            }, 0.5)
        }, [])

        return <div className={s.wrap + ' side-margins'} id="contacts" ref={wrapRef}>
            <div style={{overflow: "hidden"}}>
                <h4 ref={titleRef} className={f.didotH4}>Contact Us</h4>
            </div>
            <div className={s.infoBlock}>
                <div ref={sectionRef} className={s.wrapLines}>
                    <div className={s.contactsWrap + ' text-block'}>
                        {
                            values.phone && <div className={s.contactWrap}>
                                <h6 className={f.secularH6}>PHONE:</h6>
                                <p className={s.textSmall}>{values.phone}</p>
                            </div>
                        }
                        {
                            values.email && <div className={s.contactWrap}>
                                <h6 className={f.secularH6}>E-MAIL:</h6>
                                <p className={s.textSmall}>{values.email}</p>
                            </div>
                        }
                        {
                            values.address && <div className={s.contactWrap}>
                                <h6 className={f.secularH6}>ADDRESS:</h6>
                                <p className={s.textSmall}>{values.address}</p>
                            </div>
                        }
                    </div>
                    <div className={s.contactsWrap + ' text-block'}>
                        {
                            values.linkedinLink && <div className={`${s.contactWrap} ${s.boldLink}`}>
                                <a className={s.link} href={values.linkedinLink} target='_blank'>LinkedIn</a>
                            </div>
                        }
                        {
                            values.twitterLink && <div className={`${s.contactWrap} ${s.boldLink}`}>
                                <a className={s.link} href={values.twitterLink} target='_blank'>Twitter</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    return <Render/>
}

export default ContactUs