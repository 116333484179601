import s from './MobileMenu.module.scss'
import f from "../../../global/fonts.module.scss";
import React, {useState} from "react";
import {Link} from "react-router-dom";

const MobileMenu = () => {
    const [menuIsActive, setMenuIsActive] = useState(false)

    const handleClick = () => {
        if (menuIsActive){
            setMenuIsActive(false)
            document.body.classList.remove('no-scroll')
        } else{
            setMenuIsActive(true)
            document.body.classList.add('no-scroll')
        }
    }

    return <>
        <div onClick={() => handleClick()} className={`${s.icon}${menuIsActive ? ' ' + s.active :''}`}>
            <span className={s.line1}/>
            <div className={s.line2}>
                <span/>
                <span/>
            </div>
            <span className={s.line3}/>
        </div>
        <div className={`${s.wrap}${menuIsActive ? ' ' + s.active :''}`}>
            <div className={s.navs}>
                <Link onClick={() => handleClick()} className={`${s.navLink} ${f.secularSubtitle1}`} to="/#about">About us</Link>
                {/* <Link onClick={() => handleClick()} className={`${s.navLink} ${f.secularSubtitle1}`} to="/#works">Transactions</Link> */}
                <Link onClick={() => handleClick()} className={`${s.navLink} ${f.secularSubtitle1}`} to="/#strategy">Strategy</Link>
                <Link onClick={() => handleClick()} className={`${s.navLink} ${f.secularSubtitle1}`} to="/#contacts">Contact</Link>
            </div>
        </div>
    </>


}

export default MobileMenu