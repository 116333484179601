import {makeAutoObservable, runInAction} from "mobx";
import {ScrollTrigger} from "gsap/ScrollTrigger";

class PreloaderState {

    timeout
    isShown = true

    constructor() {
        makeAutoObservable(this)
    }

    show() {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
        runInAction(()=>this.isShown = true)
        document.body.classList.add('no-scroll')
        this.timeout = setTimeout(() => {
            runInAction(()=>this.isShown = false)
            document.body.classList.remove('no-scroll')
            ScrollTrigger.refresh()
        }, 1500)
    }

}

export default new PreloaderState()