import React, { useEffect, useRef } from "react";

import s from "./Footer.module.scss";
import f from "../../global/fonts.module.scss";
import { gsap } from "gsap";
import { useQuery } from "@apollo/client";
import { FOOTER_DOCS } from "../../queries/footer";

const Footer = () => {
  const { data, loading, error } = useQuery(FOOTER_DOCS);
  if (loading) return "";
  if (error) console.log(error.message);

  const Render = () => {
    const cRef = useRef();
    const fRef = useRef();

    const docs = data.document.data.attributes;
    let ld;
    let pp;

    if (docs) {
      docs.legalDisclosures.data
        ? (ld = "/api" + docs.legalDisclosures.data.attributes.url)
        : (ld = "/");
      docs.privacyPolicy.data
        ? (pp = "/api" + docs.privacyPolicy.data.attributes.url)
        : (pp = "/");
    } else {
      ld = "/";
      pp = "/";
    }

    useEffect(() => {
      gsap.from(cRef.current, {
        scrollTrigger: {
          trigger: fRef.current,
          start: "bottom bottom",
        },
        ease: "expo",
        y: "100%",
        opacity: 0,
        delay: 1,
      });
    }, []);

    return (
      <div className={s.wrap + " side-margins"} ref={fRef}>
        <p className={`${f.secularH6} ${s.copyright}`}>
          <span>©</span> 2024 Pantheon Capital Management Ltd | All Rights
          Reserved
        </p>
        <div className={s.line} />
        <div className={s.links}>
          <a href={ld} target="_blank" rel="noreferrer">
            <p className={`${f.secularH6}`}>LEGAL DISCLOSURES</p>
          </a>
          <a href={pp} target="_blank" rel="noreferrer">
            <p className={`${f.secularH6}`}>PRIVACY POLICY</p>
          </a>
        </div>
        <span
          className={`${s.copyr} ${f.secularSubtitle2}`}
          style={{ display: "none" }}
        >
          {" "}
          made with ❤ by <strong> 42 </strong>
        </span>
      </div>
    );
  };

  return <Render />;
};

export default Footer;
