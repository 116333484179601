import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useNavigate, useLocation} from "react-router";
import {gsap} from "gsap";

// Functional link component which delays page navigation
export const DelayLink = props => {
    const {delay, onDelayStart, onDelayEnd, replace, to, ...rest} = props;
    let timeout = null;
    const navigate = useNavigate()
    let location = useLocation();

    window.scrollTo(0,0)

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [timeout]);

    const handleClick = e => {
        // if trying to navigate to current page stop everything
        if (location?.pathname === to) return;
        onDelayStart(e, to);
        if (e.defaultPrevented) {
            return;
        }

        e.preventDefault();

        gsap.globalTimeline.kill()

        timeout = setTimeout(() => {
            navigate(to)
            if (onDelayEnd) onDelayEnd(e, to);
        }, delay);
    };

    return <Link {...rest} to={to} onClick={handleClick} />;
    // return <a {...rest} onClick={handleClick}>{props.children}</a>;
};

export default DelayLink;