import {makeAutoObservable, runInAction} from "mobx";

class CursorState {

    linkHovered = false

    constructor() {
        makeAutoObservable(this)
    }

    setLinkHovered() {
        runInAction(()=>this.linkHovered = true)
    }

    setLinkLeaved() {
        runInAction(()=>this.linkHovered = false)
    }



}

export default new CursorState()