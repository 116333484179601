import gql from "graphql-tag";


export const PROJECT_BY_SLUG = (slug) => {
    return gql`
        query project{
            projects(filters:{slug:{eq:"${slug}"}}){
                data{
                    id
                    attributes{
                        name
                        Funded
                        Details
                        detailsHeader
                        Size
                        Sector
                        Status
                        Partner
                        Coordinates
                        Address
                        Photos{
                            data{
                                attributes{
                                    url
                                }
                            }
                        }
                        MainPhoto{
                            data{
                                attributes{
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `
}

export const ALL_PROJECTS = gql`
    query ids{
        projects{
            data{
                id
                attributes{
                    name
                    Funded
                    slug
                    MainPhoto{
                        data{
                            attributes{
                                url
                            }
                        }
                    }
                }
            }
        }
    }`

