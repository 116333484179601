import React, {createRef, useEffect, useRef} from "react";

import s from './Block3.module.scss'
import f from '../../../global/fonts.module.scss'
import Marquee from "react-fast-marquee";
import Preloader from "../../../store/preloader";
import DelayedLink from "../../../components/DelayedLink/DelayedLink";
import {gsap} from "gsap";
import {useQuery} from "@apollo/client";
import {PROJECTS_COUNT_BY_ID} from "../../../queries/mainpage";
import Cursor from "../../../store/cursor";



const Block3 = ({response}) => {
    const SingleCategory = ({item}) => {
        const {data, loading, error} = useQuery(PROJECTS_COUNT_BY_ID(item.id));

        if(loading) return ''
        if(error) console.log(error.message)

        const Render = () => {
            const rev = createRef()
            const wrap = createRef()
            const title = createRef()
            const desc = createRef()
            const count = createRef()

            useEffect(()=>{
                gsap.timeline({
                    defaults:{
                        ease: 'expo',
                        duration: 1
                    },
                    scrollTrigger: {
                        trigger: wrap.current,
                        start: 'center bottom',
                    }
                })
                    .to(rev.current, {
                        scaleX: 0,
                        left: '-120%',
                        transform: 'skew(-15deg)',
                        duration: 2
                    }, 0)
                    .from(title.current, {
                        x: '20px',
                        opacity: 0
                    }, 0.2)
                    .from(desc.current, {
                        x: '30px',
                        opacity: 0
                    }, 0.5)
                    .from(count.current, {
                        opacity:0
                    }, 1)
            }, [links])

            return <div key={item.id} className={s.catWrap} ref={wrap}>
                <DelayedLink to={`/category/${item.attributes.slug}`}
                             onMouseEnter={()=>Cursor.setLinkHovered()}
                             onMouseLeave={()=>Cursor.setLinkLeaved()}
                             onDelayStart={() => Preloader.isShown = true}
                             delay={300}>
                    <div className={s.photo} >
                        <img className={s.photo + ' text-block'}
                             src={process.env.REACT_APP_BACKEND_URL + item.attributes.cover.data.attributes.url}
                             alt={item.attributes.name}/>
                        <div ref={rev} className={s.photoReveal}/>
                    </div>
                    <div className={s.info}>
                        <h4 ref={title} className={f.didotH4}>{item.attributes.name}</h4>
                        <h6 ref={desc} className={f.secularH6}>{item.attributes.description}</h6>
                        <div className={s.devWrap}>
                            <p ref={count} className={f.secularSubtitle1}>Developments: {data.projects.meta.pagination.total}</p>
                        </div>
                    </div>
                </DelayedLink>
            </div>
        }

        return <Render/>
    }

    const links = response.data.map((item) => {
        return <SingleCategory key={item.id}  item={item}/>
    })


    return <div className={'side-margins ' + s.wrap} id="works" style={{display: 'none'}}>
        {links}
        <div className={s.line}/>
        <DelayedLink to='/projects' delay={300}  onDelayStart={() => Preloader.isShown = true}>
            <div onMouseEnter={()=>Cursor.setLinkHovered()}
                 onMouseLeave={()=>Cursor.setLinkLeaved()}
                 className={s.runningLine}>
                <Marquee
                    gradient={false}
                    speed={100}
                >
                    <div className={s.textWrap}>
                        <div className={s.textBlock}>
                            <p className={f.secularH1}>Sempre Fidelis </p>
                            <p className={s.cursive}>- Always loyal to our values</p>
                        </div>
                        <div className={s.textBlock}>
                            <p className={f.secularH1}>Sempre Fidelis </p>
                            <p className={s.cursive}>- Always loyal to our values</p>
                        </div>
                        <div className={s.textBlock}>
                            <p className={f.secularH1}>Sempre Fidelis </p>
                            <p className={s.cursive}>- Always loyal to our values</p>
                        </div>
                    </div>
                </Marquee>
            </div>
        </DelayedLink>
        <div className={s.line}></div>
    </div>
}

export default Block3