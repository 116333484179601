import gql from "graphql-tag";

export const FOOTER_DOCS = gql`
    query docs{
        document{
            data{
                attributes{
                    privacyPolicy{
                        data{
                            attributes{
                                url
                            }
                        }
                    }
                    legalDisclosures{
                        data{
                            attributes{
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`