import s from './Cursor.module.scss'
import {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {observer} from "mobx-react-lite";
import cursorState from '../../store/cursor'
import {ReactComponent as Icon} from "./Vector.svg";

const Cursor = observer(() => {
    const cRef = useRef()
    const arRef = useRef()

    useEffect(()=>{
        if (cursorState.linkHovered){
            gsap.to(cRef.current, {
                scale: 1.5,
                ease: 'expo',
                duration: .5,
                borderColor: 'transparent'
            })
            gsap.to(arRef.current, {
                ease: 'expo',
                duration: .5,
                opacity:1
            })
        } else {
            gsap.to(cRef.current, {
                scale: 1,
                ease: 'expo',
                duration: .5,
                borderColor: '#ffffff'
            })
            gsap.to(arRef.current, {
                ease: 'expo',
                duration: .5,
                opacity:0
            })
        }
    }, [cursorState.linkHovered])


    window.addEventListener('mousemove', e => {
        gsap.to(cRef.current, {
            left: e.clientX - 32,
            top: e.clientY - 32,
            ease: 'expo',
            duration: .5,
            delay: .08,
            opacity:1
        })
    })


    return <div ref={cRef} className={s.cursor}>
        <div ref={arRef}>
            <Icon/>
        </div>
    </div>
})

export default Cursor