import gql from "graphql-tag";

export const ALL_PROJECTS_FROM_CATEGORY = (slug) => {
    return gql`
        query projects{
            projects(filters:{category:{slug:{eq:"${slug}"}}}){
                data{
                    id
                    attributes{
                        slug
                        name
                        Funded
                        MainPhoto{
                            data{
                                attributes{
                                    url
                                }
                            }
                        }
                    }
                }
            }
            categories(filters:{slug:{eq:"${slug}"}}){
                data{
                    attributes{
                        name
                    }
                }
            }

        }
    `
}